.Home .lander {
  position: relative;
  padding-top: 4rem;
  text-align: center;
  align-content:  center;
}


.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.columnBox {
  color: #656565;
  border: 2px solid #656565;
  text-align: center;
  margin: 2%;
  padding: 5%;
  border-radius: 10px;
  max-width: 400px;
  margin: 0 auto;
  min-height: 360px;
}

.homeCard {
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 5%;
  color: #656565
}

.homeCard .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2%;
}

@media all and (min-width: 480px) {
  .Login form {
    color: #333;
    margin: 0 auto;
  }
}

/* .login {
  height: 100vh;
  align-content: center;
  text-align: center;
  margin: auto, 0;
} */

.loginInput {
  height: 50px;
  border-radius: 40%;
  /* width: 300px; */
}

input:focus {
  background-position: 0 0;
}

.loginButton {
  height: 50px;
  width: 100%;
  border-radius: 100px !important;
  margin-top: 8px;
  background-color: #656565 !important;
  color: white !important;
}

/* .center {
  position: absolute;
  width: 300px;
  height: 50px;
  top: 30%;
  left: 50%;
  margin-left: -150px; 
  margin-top: -25px;
}​

.loginTitle {
  border-bottom: solid #333;
  border-width: 1px;
  margin-bottom: 1rem;
}

.logincard {
  padding: 5%;
  border: solid black 1px;
  border-radius: 5px;
  max-width: 400px;
  margin: 0 auto;
} */

.forgot {
  margin-top: 2rem;
  text-align: center;
}

.forgotBox {
    color: #656565;
    border: 2px solid #656565;
    text-align: center;
    margin: 2%;
    padding: 4%;
    border-radius: 10px;
    max-width: 400px;
    margin: 0 auto;
}

.passwordfield {
  border-radius: 40%;
  width: 300px;
}