.App {
  text-align: center;
}
button:focus {outline:0;}

.login-container {
  height: 100vh;
  position: relative;
}

.vertical-center {
  width: 95%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.horizontal-center {
  text-align: center;
}
.topMenuButton {
  background-color: #e5e5e5 !important;
  color: #888 !important;
  font-size: 10px !important;
  padding: 0 7px !important;
  height: 30px;
}

.topMenuButton:hover {
  background-color: #c7c7c7cb !important;
}
.primaryVLButton {
  color: white !important;
  background-color: #E86613 !important;
}
.primaryVLButton:hover {
  color: white !important;
  background-color: rgba(232, 102, 19, .9) !important;
}

.secondaryVLButton {
  color: white !important;
  background-color: #545454 !important;
}

.secondaryVLButton:hover {
  color: white !important;
  background-color: #363636 !important;
}

.vendorEdit {
  margin-left: 0.25rem;
  font-size: 12px;
  color: rgba(0,0,255, 0.8);
}

.vendorEdit:hover {
  cursor: pointer;
  color: rgba(0,0,255, 1)
}

.editLink {
  color: rgba(0,0,255, 0.8);
}

.editLink:hover {
  cursor: pointer;
  color: rgba(0,0,255, 1)
}


.mainTabNav {
  font-size: 1.3rem;
  background-color: #E86613; 
  color: white;
  border-radius: 5px;
  height: 40px;
  margin: auto;
}

.mainTabActive {
  margin-top: -5px;
  color: #E86613;
  height: 45px;
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: solid 1px grey;
  border-bottom-color: white;
}

.nav-item:hover {
  cursor: pointer;
  background-color: rgb(201, 88, 18);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.mainNavTab {
  color: white !important;
  text-decoration: none !important;
}

.top-list-item {
  font-weight: normal;
  line-height: 25px;
  display: block;
  float: left;
  padding: 0 7px;
  height: 25px;
  background: #e5e5e5;
  color: #888;
}

.top-list-item:hover {
  background: #d6d6d6;
}

.topNavLink:hover {
  cursor: pointer;
}

.first-list-item {
border-bottom-left-radius: 5px ;
}

.last-list-item {
  border-bottom-right-radius: 5px;
}

.customNav {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.customNav .navItem {
  padding: 8px 4px;
  margin-top: -8px;
}

.customNav .navItem .navText {
  padding: 8px;
  
}